import * as React from "react"
import { Box } from "../theme"

const Footer = ({ data, render }) => (
  <>
    {data}
    {/* <hr/> */}
    {/* Footer is current a place for showing off colors */}
    {/* { ['primary', 'secondary', 'accent', 'grey'].map((cat) => ( */}
    {/*   [100, 200, 300, 400, 500, 600, 700, 800, 900].map((weight) => ( */}
    {/*     <Box bg={cat+weight} key={cat+weight}>{cat+weight}</Box> */}
    {/*   )) */}

    {/* )) } */}
  </>
)
export default Footer
