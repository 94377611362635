import * as React from "react"
import { Box, Flex } from "rebass"
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import { renderingOptions } from "./MagicMaster"

import { theme } from "../theme"

const ContentfulColumns = ({ entryMap, currentLocale, auxiliaryData, ContentfulColumns: columns }) => {

  // render
  const options = renderingOptions({ entryMap, currentLocale, auxiliaryData, })

  const contentWidthPairs = prepareJsonWidthPairsForRendering(columns)
  // background needs entryMap for accessing assets
  const backgroundWidthPairs = prepareJsonWidthPairsForBackground( columns, entryMap[currentLocale] )

  const backgroundSetting = !!columns.columnsBackground ?
    backgroundRichTextToBackgroundObject( JSON.parse(columns.columnsBackground.raw), entryMap[currentLocale]) :
    emptyBackgroundObject

  const isFullWidthOverride = !!columns.isFullWidth ? {} : { maxWidth: theme.constants.containerWidth, }

  /* The 100% width background container, as we need zIndex to compose layers correctly */
  return (
    <Box data-contentful={columns.title} width="100%" sx={{ position: "relative" }}>
      <Flex flexWrap="wrap" sx={{ mx: "auto", position: "relative", ...isFullWidthOverride }}>
        {/* the 6 columns split for background */}
        <Box flexWrap="wrap" width="100%" height="100%" display={ contentWidthPairs.length === 1 ? "flex" : ['none', 'flex']}
          sx={{
            position: "absolute",
            zIndex: -1,
            overflow: "hidden",
            borderRadius: backgroundSetting.settings.rounded ? theme.radii.default : 0 ,
          }}>
          {/* the radii with overflow makes the background rounded */}
          { backgroundWidthPairs.map( ([json, width, colNum]) => (
            <Box width={[1, width/6, width/6]} height="100%" key={"bgColumn" + colNum} sx={json}></Box>
          )) }
        </Box>

        {/* the 6 columns where contents reside */}
        { contentWidthPairs.map( ([json, width, colNum], i) => (
          <Box width={[1, width/6, width/6]} key={"column" + colNum} p={2}
            sx={{ background: [
              (
                // IF width matches and column number matches (may need to add check for total col number equals?)
                ( !!backgroundWidthPairs[i] && width === backgroundWidthPairs[i][1] && colNum === backgroundWidthPairs[i][2] ) ?
                // THEN we apply the background directly to the responsive box
                backgroundWidthPairs[i][0].background :
                // ELSE there will be no background
                "none"
              ),
              "none"
            ] }}>
            { /* @ts-ignore */ }
            { documentToReactComponents( json, options ) }
          </Box>
        )) }
      </Flex>
      {/* The full-width background container, zIndex -2 */}
      <Box width="100%" height="100%" sx={{ ...backgroundSetting.json, position: "absolute", top: 0, zIndex: -2 }}></Box>
    </Box>
  )
}

function prepareJsonWidthPairsForRendering(columns) {
  // Algo somehow assumes col1 is always non-empty or the box building will be strange
  const widths = Array(7).fill(0) // for 1-index from 1-6
  // column{1..6}, with a magic getting "column0" for undefined :)
  const columnContents = widths.map((_, i) => (columns[`column${i}`]))

  const contentsToBeRendered = []
  let last = 0
  columnContents.forEach((content, i) => {
    if (!!content) {
      const contentJson = JSON.parse(content.raw)
      contentsToBeRendered.push([contentJson, i])
      widths[i] = 0
      last = i
    }
    widths[last]++
  })
  return contentsToBeRendered.map( ([json, n]) => ([json, widths[n], n]) )
}

function prepareJsonWidthPairsForBackground( columns, localizedEntryMap ) {
  // Algo somehow assumes col1 is always non-empty or the box building will be strange
  const widths = Array(7).fill(0) // for 1-index from 1-6
  // column{1..6}, with a magic getting "column0" for undefined :)
  const columnContents = widths.map((_, i) => (columns[`column${i}Background`]))

  const contentsToBeRendered = []
  let last = 0
  columnContents.forEach((content, i) => {
    if (!!content) {
      const contentJson = JSON.parse(content.raw)
      contentsToBeRendered.push([contentJson, i])
      widths[i] = 0
      last = i
    }
    widths[last]++
  })
  return contentsToBeRendered.map( ([json, n]) => (
    [backgroundRichTextToBackgroundObject( json, localizedEntryMap ).json, widths[n], n]
  ) )
}

type BackgroundObject = { json: any, settings: any }
const emptyBackgroundObject = { json: {}, settings: {} }
function backgroundRichTextToBackgroundObject(
  json,
  localizedEntryMap,
  base: BackgroundObject = emptyBackgroundObject
) : { json: any, settings: any } {
  // this function is supposed to be recursive
  switch (json.nodeType) {
    // base case: plain text
    case "text": return {
      json: !!json.value ? { ...base.json, background: theme.colors[json.value] || json.value } : base.json,
      settings: base.settings
    }
    // base case: embedded asset
    case "embedded-asset-block":
    // base case: inline asset
    case "asset-hyperlink": {
      const assetId = json.data.target.sys.id
      const url = localizedEntryMap[assetId].file.url
      return { json: { ...base.json, backgroundImage: `url(${url})`, backgroundSize: "cover", backgroundPosition: "center" }, settings: base.settings  }
    }

    // aggregation recursive case
    case "document":
    case "paragraph":
      return json.content.reduce(
        ( prevObj: BackgroundObject, content ) => (backgroundRichTextToBackgroundObject( content, localizedEntryMap, prevObj )),
        base
      )

    // computation involved recursive case
    case "blockquote":
      return json.content.reduce(
        ( prevObj: BackgroundObject, content ) => (backgroundRichTextToBackgroundObject( content, localizedEntryMap, prevObj )),
        { json: base.json, settings: { rounded: true } }
      )

    default:
  }
  console.error("case not caught:", json)
  return { json: {}, settings: {} }
}

export default ContentfulColumns
