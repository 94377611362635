import * as React from "react"
import { Box, Heading, Image, Flex, Text } from "../theme"
import { renderingOptions } from "./MagicMaster"

const ContentfulInstagram = ({ entryMap, currentLocale, auxiliaryData, ContentfulInstagram: igSettings }) => {

  // render
  const options = renderingOptions({ entryMap, currentLocale, auxiliaryData, })

  return (
    <>
      <>{JSON.stringify(igSettings)}</>
      <Heading>Showing Posts with tag {igSettings.tag}</Heading>

      { /* CAROUSEL ALBUM and VIDEO may not have caption */ }
      <>{!!auxiliaryData.igPosts && (auxiliaryData.igPosts.nodes).filter( post => !!post.caption && post.caption.indexOf(igSettings.tag) !== -1 )
            .map( ( post, i ) => (
        <Flex key={"instagram_"+i}>
          <Box width={1/2}><Image src={post.localFile.publicURL}/></Box>
          <Box width={1/2} p={3}>{post.caption.split('\n').map(str => <Text>{str}</Text>)}</Box>
          {/*console.log(post)*/}
        </Flex>

      ) )}</>
    </>
  )
}

export default ContentfulInstagram
