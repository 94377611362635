import * as React from "react"

import { graphql } from 'gatsby'
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';

import Seo from "../../components/seo"
import Footer from "../../components/Footer";
import { renderingOptions } from "../../components/MagicMaster"
import Menu from "../../components/Menu";
import ContentfulColumns from "../../components/ContentfulColumns"

import { theme, Box } from "../../theme"
import { Global, ThemeProvider, css } from '@emotion/react'

const Page = ({ data }) => {
  const entryMap = data.components.nodes
    .concat(data.assets.nodes)
    .concat(data.columns.nodes)
    .concat(data.pages.nodes)
    .concat(data.igEmbeds.nodes)
    .reduce( (obj: {}, node: any) => ({
      ...obj,
      [node.node_locale]: {
        ...obj[node.node_locale],
        [node.contentful_id]: node
      }
    }), {} )
  const options = renderingOptions({ entryMap, currentLocale: data.contentfulPage.node_locale, auxiliaryData: { igPosts: data.igPosts } })
  // @ts-ignore suppressing error for `options` again
  const render = json => documentToReactComponents( json, options )
  const content = data.contentfulPage.content ? render(JSON.parse(data.contentfulPage.content.raw)) : <p>Nothing to show yet.  :(</p>
  const landing = <ContentfulColumns ContentfulColumns={data.columns.nodes.filter( node => node.title === "landing" && node.node_locale === data.contentfulPage.node_locale )[0]} entryMap={entryMap} currentLocale={data.contentfulPage.node_locale}></ContentfulColumns>
  const footer = <ContentfulColumns ContentfulColumns={data.columns.nodes.filter( node => node.title === "footer" && node.node_locale === data.contentfulPage.node_locale )[0]} entryMap={entryMap} currentLocale={data.contentfulPage.node_locale}></ContentfulColumns>
  const isMenuShown = data.contentfulPage.isMenuShown
  const isFooterShown = data.contentfulPage.isFooterShown
  return (
    <ThemeProvider theme={theme}>
      <Global styles={css`
        body {
          width: 100vw;
          height: 100vh;
          margin: 0;
          background: #DDD;
          // background: linear-gradient(-45deg, #fe9772, #f77cae, #73a6f5, #c3f5db);
          // background-size: 400% 400%;
          // animation: gradient 15s ease infinite;
        }
        @keyframes gradient {
          0% {
            background-position: 0% 50%;
          }
          50% {
            background-position: 100% 50%;
          }
          100% {
            background-position: 0% 50%;
          }
        }

        .square:after {
          content: "";
          display: block;
          padding-bottom: 100%;
        }
        `}/>
      <Seo title={data.contentfulPage.title} />
      <Box className="background">
        {isMenuShown && <Menu data={data.menus.nodes.filter( node => node.items[0].node_locale === data.contentfulPage.node_locale )[0].items} node_locale={data.contentfulPage.node_locale} locales={data.locales.nodes} content={landing} slug={data.contentfulPage.slug} render={render} />}
        <div>{content}</div>
        {isFooterShown && <Footer data={footer} render={render}  />}
      </Box>
    </ThemeProvider>
  );
}

export const query = graphql`

query($id: String) {
  contentfulPage( id: { eq: $id } ) {
    title
    node_locale
    content {
      raw
    }
    slug
    isMenuShown
    isFooterShown
  }
  menus: allContentfulMenu(filter: {name: {eq: "Main"}}) {
    nodes {
      items {
        node_locale
        label
        svg {
          internal {
            content
          }
        }
        targetPage {
          slug
          title
        }
      }
    }
  }
  assets: allContentfulAsset {
    nodes {
      file {
        url
      }
      contentful_id
      node_locale
    }
  }
  components: allContentfulComponent {
    nodes {
      node_locale
      contentful_id
      content {
        raw
      }
      title
    }
  }
  columns: allContentfulColumns {
    nodes {
      node_locale
      contentful_id
      title
      __typename
      column1 {
        raw
      }
      column2 {
        raw
      }
      column3 {
        raw
      }
      column4 {
        raw
      }
      column5 {
        raw
      }
      column6 {
        raw
      }
      column1Background {
        raw
      }
      column2Background {
        raw
      }
      column3Background {
        raw
      }
      column4Background {
        raw
      }
      column5Background {
        raw
      }
      column6Background {
        raw
      }
      columnsBackground {
        raw
      }
      isFullWidth
    }
  }
  locales: allContentfulLocale {
    nodes {
      code
    }
  }
  pages: allContentfulPage {
    nodes {
      node_locale
      contentful_id
      slug
    }
  }
  igEmbeds: allContentfulInstagram {
    nodes {
      node_locale
      contentful_id
      __typename
      tag
    }
  }
#  igPosts: allInstaNode {
#    nodes {
#      caption
#      mediaType
#      timestamp
#      original
#      localFile {
#        publicURL
#      }
#    }
#  }
}
`

export default Page
