import * as React from "react"
import { Box, Button, Flex, Link, Image, Text } from '../theme'

import logo from "../images/svg/logo.svg"

import donate from "../images/svg/donate.svg"
import history from "../images/svg/history.svg"
import join from "../images/svg/join.svg"
import prayer from "../images/svg/prayer.svg"
import program from "../images/svg/program.svg"
import song from "../images/svg/song.svg"
import testimony from "../images/svg/testimony.svg"
import volunteer from "../images/svg/volunteer.svg"

const width = [ "20vw", null, null, "110px" ]
const icons = {
  donate, history, join, prayer, one2023: program, song, testimony, volunteer
}
const iconify = item => (item.svg ? "data:image/svg+xml;charset=utf-8," + encodeURIComponent(item.svg.internal.content) : (icons[item.targetPage.slug] || program))

const MenuButton = props => {
  const menuItem = props.item
  let slug = menuItem.targetPage.slug
  if (slug === "index") {
    slug = ""
  }
  return (
    <Link key={slug} href={`/${menuItem.node_locale.toLowerCase()}/${slug}`} sx={{ textDecoration: "none", position: "relative", width, display: "inline-block" }} my={1} className="square">
      <Flex
        flexDirection="column"
        alignItems="center"
        width={width}
        m="0"
        className="square"
        sx={{
          borderRadius: 30,
          position: "absolute",
          boxShadow: "large",
        }}
      >
        <Image src={iconify(menuItem)} sx={{ position: "absolute" }}/>
        <Text color="white" variant="default" fontSize={[ "2.5vw", 2, 3, 1 ]} sx={{ position: "absolute", bottom: 1 }}>{props.children}</Text>
      </Flex>
    </Link>
  )
}

const Menu = ({ data, node_locale, locales, content, slug, render }) => {
  const currentLocale = node_locale.toLowerCase()
  const toggleLocale = () => {
    const nextLocaleCode = locales.filter( l => l.code.toLowerCase() !== currentLocale )[0].code
    if (typeof window !== "undefined") {
      window.location.href = window.location.href.replace(currentLocale, nextLocaleCode.toLowerCase())
    }
  }
  const items = []
  data.filter(menuItem => menuItem.node_locale === node_locale).forEach(menuItem => {
    if (menuItem.targetPage.slug === "index") return
    items.push(
      <MenuButton key={menuItem.targetPage.slug} display="inline-block" mx={3} variant="default" item={menuItem}>{menuItem.targetPage.title}</MenuButton>
    )
  })

  const stickyTop = (position) =>
    <Flex width="100%" px={4} bg="primary900" sx={{
      flexDirection: "row",
      flexWrap: "wrap",
      alignItems: "center",
      position: position,
      top: 0,
      left: 0,
      zIndex: 1000
    }}>
      <Link href={`/${currentLocale}`}><Image src={logo} width={[50, 50, 75]} sx={{ display: "block" }}></Image></Link>
      <Box mx="auto" />
      {
        data.filter(menuItem => menuItem.node_locale === node_locale).map(menuItem => {
          if (menuItem.targetPage.slug === "index") return
          return <Link key={menuItem.targetPage.slug} p={[2, 2, 3]} fontSize={[1, 1, 2]} color="fcbhred" href={`/${currentLocale}/${menuItem.targetPage.slug}`} sx={{ height: "100%", textDecoration: "none", fontWeight: 700, fontFamily: "body" }}>{menuItem.targetPage.title}</Link>
        })
      }
      <Link color="fcbhred" p={[2, 2, 3]} href="#" sx={{ textDecoration: "none" }} onClick={() => toggleLocale()} fontFamily="body">{ node_locale === "en-US" ? "中" : "Eng" }</Link>
    </Flex>

      {/* <Box width="100%" bg="primary500" p={3} display={['none', 'none', 'none', 'block']}> */}
  return ( slug === "index" ?
    <>
      {stickyTop("fixed")}
      {stickyTop("block")}
      <Box width="100%" bg="fcbhred" p={3}>
        <Button bg="fcbhred" sx={{ float: "right" }} onClick={() => toggleLocale()}>{ node_locale === "en-US" ? "中" : "Eng" }</Button>
        <Box sx={{ pointerEvents: "none" }}>{content}</Box>
        <Flex m="auto" flexWrap="wrap" justifyContent="space-around">
          {items}
        </Flex>
      </Box>
    </>:
    <>
      {stickyTop("fixed")}
      {stickyTop("block")}
    </>
  )}

/*
    <Flex width="100%" bg="primary500" p={3} justifyContent="center">
      {items}
      <Button onClick={() => toggleLocale()}>{ node_locale === "en-US" ? "中" : "Eng" }</Button>
    </Flex>
 */

export default Menu
