import {
  Box     as rBox,
  Button  as rButton,
  Card    as rCard,
  Flex    as rFlex,
  Image   as rImage,
  Link    as rLink,
  Text    as rText,
  Heading as rHeading,
} from "rebass"
import { withTheme } from "@emotion/react"

// spec: https://theme-ui.com/theme-spec
const theme = {
  // https://zellwk.com/blog/media-query-units/
  // so it is responsive in proportion to browser font settings
  // 640, 832, 1024
  breakpoints: ['40em', '52em', '64em'],
  fontSizes: [
    // 0-indexed
    11,
    14, 17, 22,
    35, 45, 55,
    64, 88, 96
  ],
  colors: {
    oneblue: 'rgb(1 ,146 ,186)',
    fcbhred: 'rgb(135, 7,135)',

    /* primary ONE BLUE */
    primary100: 'hsl(183.8, 50%, 6.1%)',
    primary200: 'hsl(183.8, 50%, 26.1%)',
    primary300: 'hsl(183.8, 50%, 36.1%)',
    primary400: 'hsl(183.8, 50%, 46.1%)',
    primary500: 'rgb(1 ,146 ,186)',
    primary600: 'hsl(183.8, 50%, 60.1%)',
    primary700: 'hsl(183.8, 50%, 66.1%)',
    primary800: 'hsl(183.8, 50%, 76.1%)',
    primary900: 'hsl(183.8, 50%, 96.1%)',
    primary1000: 'rgb(37, 144,181)',

    /* secondary FCBH RED */
    secondary100: 'hsl(3, 85%, 3%)',
    secondary200: 'hsl(3, 85%, 20%)',
    secondary300: 'hsl(3, 85%, 30%)',
    secondary400: 'hsl(3, 85%, 40%)',
    secondary500: 'hsl(3, 85%, 48%)',
    secondary600: 'hsl(3, 85%, 60%)',
    secondary700: 'hsl(3, 85%, 70%)',
    secondary800: 'hsl(3, 95%, 80%)',
    secondary900: 'hsl(3, 100%, 95%)',

    /* accent GOLD */
    accent100: 'hsl(37, 100%, 8%)',
    accent200: 'hsl(37, 100%, 18%)',
    accent300: 'hsl(37, 100%, 33%)',
    accent400: 'hsl(37, 97%, 40%)',
    accent500: 'hsl(37, 95%, 48%)',
    accent600: 'hsl(40, 95%, 58%)',
    accent700: 'hsl(43, 95%, 68%)',
    accent800: 'hsl(43, 97%, 78%)',
    accent900: 'hsl(43, 100%, 98%)',

    grey100: 'hsl(0, 0%, 2%)',
    grey200: 'hsl(0, 0%, 12%)',
    grey300: 'hsl(0, 0%, 22%)',
    grey400: 'hsl(0, 0%, 32%)',
    grey500: 'hsl(0, 0%, 42%)',
    grey600: 'hsl(0, 0%, 52%)',
    grey700: 'hsl(0, 0%, 62%)',
    grey800: 'hsl(0, 0%, 72%)',
    grey900: 'hsl(0, 0%, 92%)',
  },
  space: [
    0, 4, 8, 16, 32,
    64, 96, 128, 256,
  ],
  fonts: {
    body: 'Verdana, sans-serif',
    heading: 'Gotham SSm A,Gotham SSm B,Gotham,"Helvetica Neue",Helvetica,Arial,sans-serif',
    monospace: 'Menlo, monospace',
  },
  fontWeights: {
    body: 100,
    heading: 700,
    bold: 700,
  },
  lineHeights: {
    body: 1.75,
    heading: 1.25,
  },
  radii: {
    default: 30,
  },
  shadows: {
    small: '0 0 4px rgba(0, 0, 0, .125)',
    large: '0 0 24px rgba(0, 0, 0, .125)'
  },
  variants: {
  },
  text: {
    default: {
      fontFamily: 'body',
      fontWeight: 'body',
      lineHeight: 'body',
      fontSize: [ 2, 2, 3 ],
    },
    heading: {
      marginTop: '20px',
    }
  },
  buttons: {
    primary: {
      color: 'grey900',
      bg: 'primary1000',
      fontFamily: 'body',
    },
    secondary: {
      color: 'secondary900',
      bg: 'fcbhred',
      fontFamily: 'body',
    },
    tertiary: {
      color: 'primary900',
      border: '3px solid #fff',
      bg: 'transparent',
      fontFamily: 'body',
    },
  },
  // EXTRAS! NOT IN THE SPEC
  constants: {
    containerWidth: 1000,
  }
}

const Box = withTheme(rBox)
const Button = withTheme(rButton)
const Card = withTheme(rCard)
const Flex = withTheme(rFlex)
const Image = withTheme(rImage)
const Link = withTheme(rLink)
const Text = withTheme(rText)
const Heading = withTheme(rHeading)

export {
  Box,
  Button,
  Card,
  Flex,
  Image,
  Link,
  Text,
  Heading,
  theme,
}
