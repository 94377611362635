import * as React from "react"

import { BLOCKS, INLINES, MARKS } from "@contentful/rich-text-types"
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';

import Menu from './Menu'
import ContentfulColumns from './ContentfulColumns'
import ContentfulPartner from './ContentfulPartner'
import ContentfulInstagram from './ContentfulInstagram'
// import ContentfulNews from './ContentfulNews'

import { Box, Button, Flex, Heading, Image, Link, Text, theme } from "../theme"

export type EmbeddedEntry = {
  content: any[],
  data: {
    target: {
      sys: {
        id: string,
        type: string,
        linkType: string
      }
    }
    nodeType: string
  }
}
export type ComponentProjection = {
  contentful_id: string
  content: {
    raw: string
  }
  title: string
}
export type PartnerProjection = {
  contentful_id: string
  name: string
  type: string
}

// from: https://github.com/contentful/rich-text/tree/master/packages/rich-text-react-renderer
export const renderingOptions = ({ entryMap, currentLocale, auxiliaryData = {} }) => {
  const igPosts = auxiliaryData.igPosts || {}
  // @ts-ignore
  const render = (json) => documentToReactComponents( json, renderingOptions({ entryMap, currentLocale, auxiliaryData }) )
  const containerize = (component) => (<Text variant="default" px={4} py={3} sx={{ maxWidth: theme.constants.containerWidth, mx: "auto" }}>{component}</Text>)
  const headerize = (node: { content: {nodeType: string, value: string, marks: {type: string}[], data: {}}[] }) => {
    // TODO: add buttons support
    return node.content.map((text, i) => (
      ( text.value ? text.value.split("&nbsp;").map( ( value, w, arr ) =>
        ({ marks: text.marks, value: value + ( w === arr.length - 1 ? "" : " " ) }) ) : [text] )
        .map((text) => (
          <Text as="span" sx={{ display: "inline-block" }} key={"heading"+i} color={ !text.marks || text.marks.length === 0 ? "inherit" :
              text.marks[0].type === "underline" ? "primary900" :
              text.marks[0].type === "italic" ? "secondary300" :
              text.marks[0].type === "bold" ? "primary500" :
              "inherit"}>{text.value}</Text>))
    ))
  }
  const headerSizes = [[], [ 7, 9 ], [ 4, 5, 7 ], [ 3, 4, 6 ]]
  const headerPaddings = [[], [ 4, 7 ], [0], [0]]
  return {
    renderNode: {
      [BLOCKS.HEADING_1]: (node) => containerize(<Heading py={headerPaddings[1]} fontSize={headerSizes[1]}>{headerize(node)}</Heading>),
      [BLOCKS.HEADING_2]: (node) => containerize(<Heading fontSize={headerSizes[2]}>{headerize(node)}</Heading>),
      [BLOCKS.HEADING_3]: (node) => containerize(<Heading fontSize={headerSizes[3]}>{headerize(node)}</Heading>),
      [BLOCKS.HEADING_4]: (node) => containerize(<Heading py={headerPaddings[1]} fontSize={headerSizes[1]} textAlign="center">{headerize(node)}</Heading>),
      [BLOCKS.HEADING_5]: (node) => containerize(<Heading fontSize={headerSizes[2]} textAlign="center">{headerize(node)}</Heading>),
      [BLOCKS.HEADING_6]: (node) => containerize(<Heading fontSize={headerSizes[3]} textAlign="center">{headerize(node)}</Heading>),
      [BLOCKS.QUOTE]: (node) => {
        return (<Flex alignItems="center" flexDirection="column">
          { node.content.map( (child, n) => {
            if ( child.nodeType === "paragraph" ) {
              return <React.Fragment key={n}>{
                containerize(node.content.map( (json, i) => (<React.Fragment key={i}>{render(json)}</React.Fragment>) ))
                }</React.Fragment>
            }

            return <Box m="auto" key={n}>{render(child)}</Box>
          } ) }
        </Flex>)
      },
      [BLOCKS.PARAGRAPH]: (node) => {
        // to remove the wrapping <p> tag causing validateDOMnesting errors
        return containerize(node.content.map( (json, i) => (<React.Fragment key={i}>{render(json)}</React.Fragment>) ))
      },
      [BLOCKS.OL_LIST]: (node) => {
        return node.content.map( ( item, t ) => {
          const paragraph = item.content[0]
          const fillCode = paragraph.content.length === 1 ? paragraph.content[0].value : ""
          const fillColor = theme.colors[fillCode] || "" // console.warn("invalid fill color:", fillCode)
          const list = item.content[1]
          if (!list) {
            return <React.Fragment key={t}></React.Fragment>
          }
          return <React.Fragment key={t}>
            <Flex alignItems="center">{ list.content.map( ( cellData, k ) => {
              // if only 1 content, or the 2nd content is indeed an empty list
              if ( cellData.content.length === 1 || cellData.content[1].content.length === 0 ) {
                // image. The complex filter is because content writers can leave empty hyperlinks in contentful....
                const linkNode: {
                  content: [{ value: string }],
                    data: { target: { sys: { id: string, type: string, linkType: string } } }
                } = cellData.content[0].content.filter( n => n.nodeType === "asset-hyperlink" && !!n.content[0].value )[0]

                // sometimes this closure is triggered by auto rendering in Contentful lol, it can skip layers
                if (!linkNode) {
                  return <React.Fragment key={k}></React.Fragment>
                }

                const assetId = linkNode.data.target.sys.id
                const maybeLink = linkNode.content[0].value
                const url = entryMap[currentLocale][assetId].file.url

                const content = (!url.endsWith(".svg") || !fillCode) ?
                  // normal images, or invalid fillCode
                  <Image key={k} src={url} sx={{ flex: "0 1 content", maxWidth: "50%" }}></Image> :
                  // SVG that can be colored: https://stackoverflow.com/a/43916743/6712483
                  // as it is using `sx`, we don't need to use fillColor directly
                  <Box key={k} bg={fillCode} sx={{ mask: `url(${url}) no-repeat center` }}><Image src={url} sx={{ opacity: 0 }}></Image></Box>

                  const isLink = (text: string) => text.startsWith("https://")
                return isLink(maybeLink) ? <Link key={k} href={maybeLink}>{content}</Link> : content
              }

              // paragraphssss
              const colorCode = (cellData.content[0].content.filter( c => c.nodeType === "text" && !!c.value ))[0].value
              const color = theme.colors[colorCode] || console.warn("invalid color:", colorCode, cellData.content[0])
              return (<Text key={k} variant="default" sx={{ flex: "1", color }}>{
                // unwrap "list-item"
                cellData.content[1].content.map( listItem => (listItem.content[0]) )
                .map( (node, d) => <React.Fragment key={d}>{render(node)}</React.Fragment> )
                }</Text>)
            }) }</Flex>
          </React.Fragment>
        } )
      },
      // TODO: fix this magic type "EmbeddedEntry" to be properer if you want
      [BLOCKS.EMBEDDED_ENTRY]: (node: EmbeddedEntry) => {
        const entry = entryMap[currentLocale][node.data.target.sys.id]
        if (typeof entry === "undefined" ) {
          console.warn("entry not found. did you include it in page query?")
          console.log(entryMap)
          console.log(node)
          return (<></>)
        }

        if ( entry.__typename === "ContentfulColumns" || entry.__typename === "ContentfulInstagram" ) {
          const targetName = entry.__typename
          const TargetComponent = reactComponentMap[targetName]
          // @ts-ignore
          return (<TargetComponent {...{[targetName]: entry, entryMap, currentLocale, auxiliaryData }}/>)
        }
        const targetName = "Contentful" + entry.title
        const TargetComponent = reactComponentMap[targetName]
        if (typeof TargetComponent === "undefined") {
          console.warn(`Component <${targetName}> not registered in reactComponentMap`)
          console.log(entry)
          if (entry.content == null) {
            console.warn(`The content is null. This should be a mistake as nothing can be rendered from ${targetName}`)
            // return <></>
            return (<Box width="100%" sx={{ minHeight: 140, border: "3px solid black", background: `repeating-linear-gradient(
                45deg,
                hsla(183.8, 50%, 56.1%, .7),
                hsla(183.8, 50%, 76.1%, .7) 10px,
                hsla(3, 85%, 60%, .7) 10px,
                hsla(3, 85%, 70%, .7) 20px
              )` }}>content editors please add some place holder text to Component "{entry.title}" content</Box>)
          }
          console.warn(`Programmers please work on ${targetName}`)
          return (<Box width="100%" sx={{ minHeight: 140, border: "3px solid black", background: `repeating-linear-gradient(
              45deg,
              hsla(183.8, 50%, 56.1%, .7),
              hsla(183.8, 50%, 76.1%, .7) 10px,
              hsla(3, 85%, 60%, .7) 10px,
              hsla(3, 85%, 70%, .7) 20px
            )` }}>{render(JSON.parse(entry.content.raw))}</Box>)
        }
        // the following lines are useful in getting the real Component given only the INLINE link
        // via entry.data.target.sys.id (please check if Sunny had a typo here XD)
        // console.log(entry)
        // console.log(componentMap[entry.data.target.contentful_id])
        // @ts-ignore
        return (<TargetComponent {...{[targetName]: entry, entryMap, currentLocale}}/>)
      },
      [BLOCKS.EMBEDDED_ASSET]: (node: { data: { target: { sys: { id: string, type: string, linkType: string } } } }) => {
        const assetId = node.data.target.sys.id
        const file = entryMap[currentLocale][assetId].file
        if (!file) console.error("file not found for embedded asset", node, entryMap)
        const url = file.url
        return <Image m="auto" p={[ 0, null, null, 4 ]} maxWidth={`min(100%, ${theme.constants.containerWidth}px)`} src={url} display="block"/>
      },
      [INLINES.EMBEDDED_ENTRY]: (node: EmbeddedEntry) => {
        console.warn("Inline embed found. Please consider using Blocks as Inlines are not supported")
        console.log(node)
        return (<></>)
      },
      [INLINES.HYPERLINK]: (node: {
        data: { uri: string },
        content: [ {
          value: string,
          nodeType: string,
          marks: [],
          data: {}
        } ]
      }) => {
        const needsSpecialTreatment = (node: {
          data: { uri: string },
          content: [ {
            value: string,
            nodeType: string,
            marks: [],
            data: {}
          } ]
        }) => {
          // intentionally not using inline return for easier debug or nested if
          return (!!node.content[0]
            && node.content[0].value.substr(0, 1) === "["
            && node.content[0].value.substr(-1) === "]"
          )
        }

        // TODO: support styling... that will make content an array with more than 1 items, some with `marks`
        if (!needsSpecialTreatment(node)) return (<Link href={node.data.uri} color="inherit">{node.content[0].value}</Link>)

        if (node.content[0].value === "[embed]") {
          const embedUri = ( uri => {
            // e.g. https://www.youtube.com/playlist?list=PL0OlC6B6A1P0_YcFUiXN0swOrGWRuXRG6
            if (uri.includes("playlist?list"))
              return "https://www.youtube.com/embed/videoseries?list=" + uri.substring(uri.lastIndexOf("=") + 1)

            // e.g. https://youtu.be/1X_1BylPMH0
            if (uri.includes("youtu.be"))
              return "https://www.youtube.com/embed/" + uri.substring(uri.lastIndexOf("/") + 1)

            // e.g. https://www.youtube.com/watch?v=nv4jQxkdW5o
            if (uri.includes("watch?v"))
              return "https://www.youtube.com/embed/" + uri.substring(uri.lastIndexOf("=") + 1)

            console.error("embed not handled", uri)
            return ""
          } )(node.data.uri)

          return (<Box
            sx={{
              width: '100%',
              height: 0,
              paddingBottom: (900 / 16) + '%',
              position: 'relative',
              overflow: 'hidden',
              margin: 'auto',
              '& > iframe': {
                position: 'absolute',
                  width: '100%',
                  height: '100%',
                  top: 0,
                  bottom: 0,
                  left: 0,
                  border: 0
              }
            }}>
            <iframe
              width='560'
              height='315'
              src={embedUri}
              frameBorder='0'
              title="YouTube video player"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen />
          </Box>)
        }

        let contentString = node.content[0].value.slice(1,-1)
        let variant = 0
        const variants = ["primary", "secondary", "tertiary"]
        while ( contentString[0] === "[" && contentString.substr(-1) === "]" ) {
          contentString = contentString.slice(1,-1)
          variant++
        }

        return (<Link href={node.data.uri} sx={{ textDecoration: "none" }}>
          <Button variant={variants[variant]} display="inline-block" sx={{ cursor: "pointer" }}>{contentString}</Button>
        </Link>)
      },
      [INLINES.ENTRY_HYPERLINK]: (node: { data: { target: { sys: { id: string } } }, content: [ { value: string } ] }) => {
        const needsSpecialTreatment = (node: {
          data: { target: { sys: { id: string } } }, content: [ { value: string, } ]
        }) => {
          // intentionally not using inline return for easier debug or nested if
          return (!!node.content[0]
            && node.content[0].value.substr(0, 1) === "["
            && node.content[0].value.substr(-1) === "]"
          )
        }
        const link = `/${currentLocale.toLowerCase()}/${entryMap[currentLocale][node.data.target.sys.id].slug}`
        if (!needsSpecialTreatment(node)) return (<Link href={link}>{node.content[0].value}</Link>)

        let contentString = node.content[0].value.slice(1,-1)
        let variant = 0
        const variants = ["primary", "secondary", "tertiary"]
        while ( contentString[0] === "[" && contentString.substr(-1) === "]" ) {
          contentString = contentString.slice(1,-1)
          variant++
        }

        return (<Link href={link} sx={{ textDecoration: "none" }}>
          <Button variant={variants[variant]} display="inline-block" sx={{ cursor: "pointer" }}>{contentString}</Button>
        </Link>)
      }
    },
    renderText: (text: string) => {
      if ( text === "[x]" ) return ""
      return <>{text}</>
    },
    renderMark: {
      [MARKS.UNDERLINE]: (text) => {
        return <Text as="span" color="primary900">{text}</Text>
      }
    }
  }
}

const reactComponentMap = {
  Menu,
  ContentfulColumns,
  ContentfulPartner,
  ContentfulInstagram,
  // ContentfulNews,
}
